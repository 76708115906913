// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const createPaymentDebtRequest = createAction('CREATE_PAYMENT_DEBT_REQUEST');
export const createPaymentDebtSuccess = createAction('CREATE_PAYMENT_DEBT_SUCCESS');
export const createPaymentDebtFailure = createAction('CREATE_PAYMENT_DEBT_FAILURE');
export const resetCreatePaymentDebt = createAction('RESET_CREATE_PAYMENT_DEBT');

export const getDebtLogRequest = createAction('GET_DEBT_LOG_REQUEST');
export const getDebtLogSuccess = createAction('GET_DEBT_LOG_SUCCESS');
export const getDebtLogFailure = createAction('GET_DEBT_LOG_FAILURE');
export const resetGetDebtLogState = createAction('RESET_GET_DEBT_LOG_STATE');

export const getDebtOrderRequest = createAction('GET_DEBT_ORDER_REQUEST');
export const getDebtOrderSuccess = createAction('GET_DEBT_ORDER_SUCCESS');
export const getDebtOrderFailure = createAction('GET_DEBT_ORDER_FAILURE');
export const resetGetDebtOrderState = createAction('RESET_GET_DEBT_ORDER_STATE');

export const getDebtCustomerRequest = createAction('GET_DEBT_CUSTOMER_REQUEST');
export const getDebtCustomerSuccess = createAction('GET_DEBT_CUSTOMER_SUCCESS');
export const getDebtCustomerFailure = createAction('GET_DEBT_CUSTOMER_FAILURE');
export const resetGetDebtCustomerState = createAction('RESET_GET_DEBT_CUSTOMER_STATE');

export const resetDebtState = createAction('RESET_DEBT_STATE');
