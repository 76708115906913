// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isCreatePaymentDebtRequest: false,
  isCreatePaymentDebtSuccess: false,
  isCreatePaymentDebtFailure: false,
  //
  isGetDebtLogRequest: false,
  isGetDebtLogSuccess: false,
  isGetDebtLogFailure: false,
  getDebtLogState: {},
  //
  isGetDebtOrderRequest: false,
  isGetDebtOrderSuccess: false,
  isGetDebtOrderFailure: false,
  getDebtOrderState: {},
  //
  isGetDebtCustomerRequest: false,
  isGetDebtCustomerSuccess: false,
  isGetDebtCustomerFailure: false,
  getDebtCustomerState: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region create payment debt
    [Actions.createPaymentDebtRequest]: (state) => ({
      ...state,
      isCreatePaymentDebtRequest: true,
      isCreatePaymentDebtSuccess: false,
      isCreatePaymentDebtFailure: false,
    }),
    [Actions.createPaymentDebtSuccess]: (state) => ({
      ...state,
      isCreatePaymentDebtRequest: false,
      isCreatePaymentDebtSuccess: true,
      isCreatePaymentDebtFailure: false,
    }),
    [Actions.createPaymentDebtFailure]: (state, { payload }) => ({
      ...state,
      isCreatePaymentDebtRequest: false,
      isCreatePaymentDebtSuccess: false,
      isCreatePaymentDebtFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreatePaymentDebt]: (state) => ({
      ...state,
      isCreatePaymentDebtRequest: false,
      isCreatePaymentDebtSuccess: false,
      isCreatePaymentDebtFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region debt log
    [Actions.getDebtLogRequest]: (state) => ({
      ...state,
      isGetDebtLogRequest: true,
      isGetDebtLogSuccess: false,
      isGetDebtLogFailure: false,
    }),
    [Actions.getDebtLogSuccess]: (state, { payload }) => ({
      ...state,
      isGetDebtLogRequest: false,
      isGetDebtLogSuccess: true,
      isGetDebtLogFailure: false,
      getDebtLogState: payload,
    }),
    [Actions.getDebtLogFailure]: (state, { payload }) => ({
      ...state,
      isGetDebtLogRequest: false,
      isGetDebtLogSuccess: false,
      isGetDebtLogFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetDebtLogState]: (state) => ({
      ...state,
      isGetDebtLogRequest: false,
      isGetDebtLogSuccess: false,
      isGetDebtLogFailure: false,
      getDebtLogState: {},
      errorMessages: [],
    }),
    // #endregion

    // #region debt order
    [Actions.getDebtOrderRequest]: (state) => ({
      ...state,
      isGetDebtOrderRequest: true,
      isGetDebtOrderSuccess: false,
      isGetDebtOrderFailure: false,
    }),
    [Actions.getDebtOrderSuccess]: (state, { payload }) => ({
      ...state,
      isGetDebtOrderRequest: false,
      isGetDebtOrderSuccess: true,
      isGetDebtOrderFailure: false,
      getDebtOrderState: payload,
    }),
    [Actions.getDebtOrderFailure]: (state, { payload }) => ({
      ...state,
      isGetDebtOrderRequest: false,
      isGetDebtOrderSuccess: false,
      isGetDebtOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetDebtOrderState]: (state) => ({
      ...state,
      isGetDebtOrderRequest: false,
      isGetDebtOrderSuccess: false,
      isGetDebtOrderFailure: false,
      getDebtOrderState: {},
      errorMessages: [],
    }),
    // #endregion

    // #region debt customer
    [Actions.getDebtCustomerRequest]: (state) => ({
      ...state,
      isGetDebtCustomerRequest: true,
      isGetDebtCustomerSuccess: false,
      isGetDebtCustomerFailure: false,
    }),
    [Actions.getDebtCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isGetDebtCustomerRequest: false,
      isGetDebtCustomerSuccess: true,
      isGetDebtCustomerFailure: false,
      getDebtCustomerState: payload,
    }),
    [Actions.getDebtCustomerFailure]: (state, { payload }) => ({
      ...state,
      isGetDebtCustomerRequest: false,
      isGetDebtCustomerSuccess: false,
      isGetDebtCustomerFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetDebtCustomerState]: (state) => ({
      ...state,
      isGetDebtCustomerRequest: false,
      isGetDebtCustomerSuccess: false,
      isGetDebtCustomerFailure: false,
      getDebtCustomerState: {},
      errorMessages: [],
    }),
    // #endregion

    // #region : Local
    [Actions.resetDebtState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
