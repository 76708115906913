/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* createPaymentDebt({ payload }) {
  try {
    yield call(() => axiosMicro.post('/debts/payment', payload));
    yield put(Actions.createPaymentDebtSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createPaymentDebtFailure(messages));
    }
  }
}

function* getDebtLog({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/debts/logs', {
      params: payload,
    }));
    yield put(Actions.getDebtLogSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getDebtLogFailure(messages));
    }
  }
}

function* getDebtOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/debts/orders', {
      params: payload,
    }));
    yield put(Actions.getDebtOrderSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getDebtOrderFailure(messages));
    }
  }
}

function* getDebtCustomer({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/debts/customers', {
      params: payload,
    }));
    yield put(Actions.getDebtCustomerSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getDebtCustomerFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.createPaymentDebtRequest, createPaymentDebt);
  yield takeLatest(Actions.getDebtLogRequest, getDebtLog);
  yield takeLatest(Actions.getDebtOrderRequest, getDebtOrder);
  yield takeLatest(Actions.getDebtCustomerRequest, getDebtCustomer);
}
